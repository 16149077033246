<script setup>
import { onBeforeUnmount, onMounted } from 'vue'

const cdn_archivos_dominio = process.env.VUE_APP_CDN_ARCHIVOS
const url_gema = process.env.VUE_APP_GEMA
const domain = process.env.VUE_APP_ENI_DOMAIN
const base_route = process.env.VUE_APP_BASE_ROUTE

function calHeight() {
  const innerheight = window.innerHeight / 100
  document
    .querySelector(':root')
    .style.setProperty('--vh', `${innerheight.toString()}px`)
}

calHeight()

onMounted(() => {
  window.addEventListener('resize', calHeight)
})

onBeforeUnmount(() => {
  window.addEventListener('resize', calHeight)
})
</script>

<template>
  <main id="principal">
    <div class="contenedor-portada">
      <video
        id="v-escritorio"
        class="portada"
        autoplay
        muted
        loop
        playsinline
      >
        <source
          :src="`${cdn_archivos_dominio}/portada/socioecologia-esc.webm`"
          type="video/webm"
        />
      </video>
      <video
        id="v-movil"
        class="portada"
        autoplay
        muted
        loop
        playsinline
      >
        <source
          :src="`${cdn_archivos_dominio}/portada/socioecologia-mov.webm`"
          type="video/webm"
        />
      </video>

      <div class="degradado-negro-abajo">
        <div class="contenido-abajo">
          <div class="contenedor ancho-fijo">
            <h1 class="titulo-eni texto-centrado texto-color-1 oculto-mov">
              Sistemas socioecológicos
            </h1>
            <h1 class="titulo-eni texto-centrado texto-color-1 oculto-esc">
              Sistemas socio-ecológicos
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div class="contenedor ancho-lectura m-y-10">
      <h2 class="texto-centrado m-y-8-esc m-y-4-mov m-x-auto">
        Ecosistema Nacional Informático de Sistemas socioecológicos
      </h2>
      <p class="texto-alto m-y-8-esc m-y-4-mov">
        El Ecosistema Nacional Informático de Sistemas socioecológicos ofrece un
        espacio de acceso abierto para el análisis de datos y visualización de
        información generada en el marco del Programa Nacional Estratégico de
        Sistemas socioecológicos.
      </p>
    </div>

    <div class="contenedor ancho-fijo m-y-10">
      <a
        class="tarjeta-hipervinculo-externo tarjeta-eni texto-color-1"
        :href="`${domain}${base_route}texcoco`"
        target="_blank"
        rel="noopener noreferrer"
      >
        <picture class="fondo-imagen">
          <img
            class="fondo-imagen"
            :src="`./capitulo/tarjeta-texcoco.png`"
          />
        </picture>

        <div class="degradado-negro-abajo">
          <div class="contenido-abajo">
            <p class="titulo-pagina">Rescate del Lago de Texcoco</p>
          </div>
        </div>
      </a>
      <br />
      <a
        class="tarjeta-hipervinculo-externo titulo-pagina texto-color-1"
        :href="`${url_gema}/visualizador?seccion=sistemas-socioecologicos`"
        target="_blank"
        rel="noopener noreferrer"
      >
        <picture class="fondo-imagen">
          <img
            class="fondo-imagen"
            :src="`${cdn_archivos_dominio}/tarjetas/eni-ss-gema.png`"
          />
        </picture>

        <div class="degradado-negro-abajo">
          <div class="contenido-abajo">
            <p class="titulo-tarjeta-gema">
              Explora capas del tema sistemas socioecológicos
            </p>
            <hr />
            <p class="subtitulo-capitulo texto-color-1">
              Gestor de mapas (Gema)
            </p>
          </div>
        </div>
      </a>
    </div>
  </main>
</template>

<style lang="scss" scoped>
.contenedor-portada {
  #v-escritorio {
    display: none;
    @media (min-width: map-get($breakpoints, 'esc')) {
      display: block;
    }
  }
  #v-movil {
    display: block;
    @media (min-width: map-get($breakpoints, 'esc')) {
      display: none;
    }
  }
}

.tarjeta-hipervinculo-externo {
  min-height: 320px;
  .fondo-imagen {
    img {
      width: 100%;
    }
  }
  .degradado-negro-abajo {
    .titulo-pagina {
      color: #ffffff;
    }
  }
}
</style>
